/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import '../App.css'
import '../styles/MainContainer.css'
import TextArea from '../components/TextArea.tsx'
import { ReactComponent as BackIcon } from '../icons/back.svg'
import TopBar from '../components/TopBar'
import { apiUrl } from '../utils/constants.ts'
import { useQueryParam, StringParam } from 'use-query-params'
import ClipLoader from 'react-spinners/ClipLoader'
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-hot-toast'
import { useAccount } from 'wagmi'

const EditProfile = () => {
  let [addressDatas] = useQueryParam('address', StringParam)
  const { address } = useAccount()
  const history = useHistory()
  const [apiKey, setApiKey] = useState('')
  const [personality, setPersonality] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCorcel, setIsCorcel] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        apiUrl + "/api/getAgent/" + addressDatas,
        {
          method: 'GET'
        }
      ).then(async res => {
        let data = await res.json()
        if (data !== null) {
          setIsCorcel(data.isCorcel)
          setApiKey(data.apiKey)
          setPersonality(data.personality)
        }
      })
    }
    fetchData();
  }, [])

  const saveAgent = async () => {
    setIsLoading(true)
    const sendData = {
      tokenAddress: addressDatas,
      creator: address,
      isCorcel: isCorcel,
      apiKey: apiKey,
      personality: personality
    }
    const response = await fetch(apiUrl + '/api/addAgent', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'error',
      body: JSON.stringify(sendData)
    });
    if (response.status !== 200) {
      const { error } = await response.json()
      toast.error('There is a problem in changing information. Please try again later')
    }
    setIsLoading(false)
  }

  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className="max-w-7xl m-auto pt-36 pb-24 px-4 sm:py-10">
            <div className='flex justify-end mb-2'>
              <button
                className="flex gap-2 items-center bg-[#12141688] rounded-[25px] px-4 py-2 text-white"
                onClick={() => history.goBack()}
              >
                <BackIcon className="back-icon" />
                &nbsp;Back
              </button>
            </div>
            <section className="ProfileBox">
              <>
                <div className="py-6 flex flex-col gap-4">
                  <div className="text-[#fff] w-full text-[20px] font-bold">
                    Agent Information
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-6 w-full'>
                    <section className="flex flex-col gap-4 w-full bg-[#12141688] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                      <div className='LpBalance'>
                        <div className="flex items-center text-[12px] text-[#686c74]">
                          API Key Provider
                          <a className='flex pl-4' id="KeyProvider-anchor-element">
                            <Tooltip
                              anchorSelect="#KeyProvider-anchor-element"
                              className='w-64 md:w-80 lg:w-96 max-w-sm'
                              content={`Choose your Provider`}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="white"
                              strokeWidth={2}
                              className="h-5 w-5 cursor-pointer text-blue-gray-500"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <div className="fairlaunch-allocation-buttons-container">
                            <button
                              className="fairlaunch-allocation-button"
                              onClick={() => setIsCorcel(true)}
                              style={
                                isCorcel === true
                                  ? {}
                                  : { background: 'transparent', color: '#686c74', border: 'solid #686c74 1px' }
                              }
                            >
                              Corcel
                            </button>
                            <button
                              className="fairlaunch-allocation-button"
                              onClick={() => setIsCorcel(false)}
                              style={
                                isCorcel === false
                                  ? {}
                                  : { background: 'transparent', color: '#686c74', border: 'solid #686c74 1px' }
                              }
                            >
                              OPENAI
                            </button>
                          </div>
                        </section>
                      </section>
                    </section>

                    <section className="flex flex-col w-full bg-[#12141688] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                      <div className='LpBalance'>
                        <p className="text-[12px] text-[#686c74]" style={{ order: '0' }}>
                          API Key
                          <span style={{ color: 'red' }}>*</span>
                        </p>
                      </div>
                      <TextArea
                        rows={4}
                        placeholder="Enter API Key"
                        changeValue={setApiKey}
                        value={apiKey}
                      />
                    </section>

                    <section className="md:col-span-2 flex flex-col w-full bg-[#12141688] px-[16px] py-[14px] rounded-[16px] border border-[#8c00f5]">
                      <div className='LpBalance'>
                        <p className="text-[12px] text-[#686c74]" style={{ order: '0' }}>
                          Personality
                          <span style={{ color: 'red' }}>*</span>
                        </p>
                      </div>
                      <TextArea
                        rows={4}
                        placeholder="Enter Personality"
                        changeValue={setPersonality}
                        value={personality}
                      />
                    </section>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <button className="save-button" onClick={saveAgent}>
                      {isLoading === false
                        ? 'Save'
                        :
                        <ClipLoader
                          color={'#fff'}
                          loading={isLoading}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />}
                    </button>
                  </div>
                </div>
              </>
            </section>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default EditProfile
