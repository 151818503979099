import React, { useEffect } from "react";
import AllLaunches from "./container/board";
import CreateBlack from "./container/create.tsx";
import NotFound from "./container/NotFound";
import BuyPage from "./container/BuyPage";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params';
import { WagmiProvider } from 'wagmi'
import { createAppKit } from '@reown/appkit/react'
import { base, arbitrum, mainnet, bsc, defineChain } from '@reown/appkit/networks'
import Profile from "./container/Profile.tsx";
import EditProfile from "./container/EditProfile";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import './index.css';
import Faq from "./container/Faq";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'

const projectId = '4807d388fe495226b7fc14743af2e1d9'

const queryClient = new QueryClient()

const SubtensorEVM = defineChain({
  id: 945,
  caipNetworkId: 'eip155:945',
  chainNamespace: 'eip155',
  name: 'Subtensor EVM',
  nativeCurrency: {
    name: "TAO",
    symbol: "TAO",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://evm-testnet.dev.opentensor.ai"],
    },
  },
  blockExplorers: {
    default: {
      name: "Taostats Explorer",
      url: "https://evm-testscan.dev.opentensor.ai",
    },
  },
  contracts: {
    // Add the contracts here
  }
})

export const networks = [mainnet, base, bsc]

const metadata = {
  name: 'AppKit',
  description: 'HotCurves',
  url: 'https://hotcurves.fun/', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/179229932']
}

export const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
  ssr: true
})

createAppKit({
  adapters: [wagmiAdapter],
  networks,
  projectId,
  metadata,
  features: {
    analytics: true // Optional - defaults to your Cloud configuration
  },
  themeVariables: {
    '--w3m-accent': '#686c74',
    '--w3m-color-mix': '#121314',
    '--w3m-color-mix-strength': 40
  },
})

const App = () => {
  return (
    <WagmiProvider reconnectOnMount config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <QueryParamProvider>
            <div>
              <Toaster
                position="top-right"
                reverseOrder={true}
                toastOptions={{ duration: 5000 }}
              >
                {(t) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <ToastBar onClick={() => alert(1)} toast={t} />
                  </div>
                )}
              </Toaster>
              <Switch>
                <Route exact path="/">
                  <AllLaunches />
                </Route>
                {/* <Route exact path="/MyContributions">
                  <MyContributions />
                </Route> */}
                <Route exact path="/dashboard">
                  <AllLaunches />
                </Route>
                <Route exact path="/createAgent">
                  <CreateBlack />
                </Route>
                <Route exact path="/Buy">
                  <BuyPage />
                </Route>
                <Route exact path="/profile">
                  <Profile />
                </Route>
                <Route exact path="/edit">
                  <EditProfile />
                </Route>
                <Route exact path="/faq">
                  <Faq />
                </Route>
                <Route exact path="/NotFound">
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </QueryParamProvider>
        </Router>
      </QueryClientProvider>
    </WagmiProvider >
  );
};

export default App;
