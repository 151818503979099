import { http, createConfig } from 'wagmi'
import { bsc, base } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'

const projectId = '4807d388fe495226b7fc14743af2e1d9'

const SubtensorEVM = {
  id: 945,
  network: "Subtensor EVM",
  name: "Subtensor EVM",
  nativeCurrency: {
    name: "TAO",
    symbol: "TAO",
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ["https://evm-testnet.dev.opentensor.ai"],
    },
  },
  blockExplorers: {
    default: {
      name: "Taostats Explorer",
      name: "Taostats Explorer",
      url: "https://evm-testscan.dev.opentensor.ai",
    },
  },
  testnet: false,
};

const chains = [
  // bsc,
  // mainnet,
  base,
  // polygon,
  // sepolia_c,
  // apechain,
  SubtensorEVM
]

export const config = createConfig({
  chains,
  connectors: [injected(), walletConnect({ projectId }),],
  ssr: true,
  transports: {
    [base.id]: http(),
    [SubtensorEVM.id]: http()
  },
})