/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { readContract, writeContract } from '@wagmi/core'
import '../App.css'
import MultiCallAbi from '../config/MultiCallAbi.json'
import InfoAbi from '../config/InfoAbi.json'
import '../styles/MainContainer.css'
import LaunchpadCard from '../components/LaunchpadCard'
import ClipLoader from 'react-spinners/ClipLoader'
import TopBar from '../components/TopBar'
import banner from '../icons/RevenueBanner.png'
import ThunderIcon from '../icons/thunder.svg'
import banner1 from '../icons/RevenueBanner-mobile.png'
import ChartIcon from '../icons/graph.svg'
import StarIcon from '../icons/star.svg'
import StarlineIcon from '../icons/star-line.svg'
import UsersIcon from '../icons/users.svg'
import ClockIcon from '../icons/alarm.svg'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import Cookies from 'universal-cookie';
import formatNumber, { imageUrl, ethPriceApiUrl, supportedChainIds, chainLogos, apiUrl, mc, coinNames, web3Clients } from '../utils/constants.ts'
import { getMulticallAddress, getInfoAddress, getDefaultAddress } from '../utils/addressHelpers.ts'
import Slider from '../components/Slider.jsx'
import Web3 from 'web3'
import rot13 from '../../utils/encode.ts'
import { useQueryParam, StringParam } from 'use-query-params'
import { config } from '../config.jsx'
import Footer from '../components/Footer';
import ChadAbi from '../config/ChadPumpAbi.json'
import { useAccount } from 'wagmi'
import { toast } from 'react-hot-toast'
import { wagmiAdapter } from '../App.jsx'

const App = () => {
  let [loading, setLoading] = useState(false)
  const [chadLists, setChadListData] = useState([])
  const [accumFee, setAccumFee] = useState()
  const [currentLength,] = useState(0)
  const cookies = new Cookies();
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [buyAmount, setBuyAmount] = useState('1')
  const [etherPrice, setEtherPrice] = useState(0)
  const [poolAddress, setPoolAddress] = useState('');
  const [creating1, setCreating1] = useState(false);
  const [watchlist, setWatchlist] = useState([]);
  const [selectedItem, setSelectedItem] = useState('1W');

  let [ref] = useQueryParam('ref', StringParam)
  const { address } = useAccount()

  if (ref) {
    if (Web3.utils.isAddress(rot13(ref))) {
      cookies.set('ref', ref)
    }
  }
  useEffect(() => {
    const FetchData = async () => {
      try {
        setLoading(true)

        // if (!cookies.get("show-how-it-works")) {
        //   setModalIsOpen(true)
        // }
        const allHistoryRes = await fetch(apiUrl + `/api/getHistoryAll`, { method: 'GET' })
        const allHistory = await allHistoryRes.json()

        let totalAccumFee = 0;
        for (const chainId of supportedChainIds) {
          const ethPriceResponse = await fetch(ethPriceApiUrl[chainId], { method: 'GET' });
          const ethPriceData = await ethPriceResponse.json();
          const ethPrice = ethPriceData.USD;
          setEtherPrice(ethPrice);

          const mainInfo = await readContract(wagmiAdapter.wagmiConfig, {
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getMainInfo',
            chainId
          })

          const otherInfo = await readContract(wagmiAdapter.wagmiConfig, {
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getOtherInfo',
            chainId
          })

          const restInfo = await readContract(wagmiAdapter.wagmiConfig, {
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getRestInfo',
            chainId
          })

          const tokenInfo = await readContract(wagmiAdapter.wagmiConfig, {
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getTokenAddresses',
            chainId
          })

          const accumFeeAmt = await readContract(wagmiAdapter.wagmiConfig, {
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getTotalAccumFee',
            chainId
          })
          totalAccumFee += (Number(accumFeeAmt) * ethPrice / 10 ** 18);

          if (mainInfo[0].length > 0) {
            for (let i = mainInfo[0].length - 1; i >= 0; i--) {
              let startTime = Number(mainInfo[0][i])
              const lpCreated = restInfo[1][i]
              let progress
              const contractAddress = mainInfo[5][i]
              const tokenAddress = tokenInfo[i]
              const virtualLpAmounts = Number(mainInfo[2][i]) * ethPrice
              const virtualLpTokenAmounts = Number(mainInfo[1][i]) / 10 ** 18
              const tokenPrice = Number(mainInfo[3][i])
              const marketCap = (tokenPrice * 1000000000) / 10 ** 12
              const website = otherInfo[2][i]
              const twitter = otherInfo[3][i]
              const telegram = otherInfo[4][i]
              progress = lpCreated ? 100 : marketCap * 100 / mc[chainId]
              const liquidity = virtualLpAmounts * 2
              const name = otherInfo[0][i]
              const symbol = otherInfo[1][i]
              const description = restInfo[2][i]
              let logoUrl = imageUrl + contractAddress + '-logo.png'
              let bannerUrl = imageUrl + contractAddress + '-banner.png'
              let blockchainLogoUrl = chainLogos[chainId]
              let devAddress = mainInfo[6][i]
              let raisingPercent = Number(restInfo[3][i]) / 100;
              let isRising = restInfo[4][i];
              let dexName = chainId === 56 ? 'PancakeSwap' : chainId === 945 ? 'HotKeySwap' : 'Uniswap'
              const featureTime = Number(restInfo[0][i])
              let lastAction
              let lastActionNumber
              const filteredList = allHistory.filter(item => item.contract === contractAddress)
              if (filteredList.length === 0) {
                lastAction = 'bought'
                lastActionNumber = 0
              } else {
                const firstType = filteredList[0].type;
                let count = 0;
                for (const item of filteredList) {
                  if (item.type === firstType) {
                    count++;
                  } else {
                    break;
                  }
                }
                lastAction = firstType;
                lastActionNumber = count;
              }
              // get holders

              let top15HoldersPercent = 0
              let devPercent = 0
              let holdersNumber = 0
              try {
                if (tokenAddress) {
                  if (Number(chainId) !== 945) {
                    await fetch(
                      `https://deep-index.moralis.io/api/v2.2/erc20/${tokenAddress}/owners?chain=0x${Number(chainId).toString(16)}&order=DESC`,
                      // `https://api.routescan.io/v2/network/testnet/evm/${chainId}/erc20/${tokenAddress}/holders`,
                      {
                        method: 'GET',
                        headers: {
                          'accept': 'application/json',
                          'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijc5ODM3MzE0LTAxYjktNDdmYy1hOTdiLTBhOGZhNWJiZGY1MyIsIm9yZ0lkIjoiNDA4MzMwIiwidXNlcklkIjoiNDE5NTgyIiwidHlwZUlkIjoiNjA1YjIzZWYtY2JiYy00N2IyLWFhOWMtYWVlOWNmZGFiNjViIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjYyNTk4MDYsImV4cCI6NDg4MjAxOTgwNn0.ZK78iZeuKpjMXjJp3QZSo6msf37m4hDZiEi10FjmgI4'
                        }
                      }
                    ).then(async res => {
                      let data = await res.json()
                      if (data.result) {
                        let holderDatas = data.result
                        console.log('holderDatas', holderDatas)
                        if (holderDatas && holderDatas.length > 0) {
                          holderDatas.sort((a, b) => b.percentage_relative_to_total_supply - a.percentage_relative_to_total_supply)
                            .map((holder, index) => {
                              if (index < 15) {
                                top15HoldersPercent += holder.percentage_relative_to_total_supply
                              }
                              if (holder.owner_address.toString().toLowerCase() === devAddress.toString().toLowerCase()) {
                                devPercent = holder.percentage_relative_to_total_supply
                              }
                              holdersNumber++
                            })
                        }
                      }
                    })
                  } else {
                    await fetch(
                      // `https://deep-index.moralis.io/api/v2.2/erc20/${tokenAddress}/owners?chain=0x${Number(chainId).toString(16)}&order=DESC`,
                      // `https://api.routescan.io/v2/network/testnet/evm/${chainId}/erc20/${tokenAddress}/holders`,
                      `https://bittensorexplorer.hotkeyswap.com/api/v2/tokens/${tokenAddress}/holders`,
                      {
                        method: 'GET',
                        // headers: {
                        //   'accept': 'application/json',
                        //   'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijc5ODM3MzE0LTAxYjktNDdmYy1hOTdiLTBhOGZhNWJiZGY1MyIsIm9yZ0lkIjoiNDA4MzMwIiwidXNlcklkIjoiNDE5NTgyIiwidHlwZUlkIjoiNjA1YjIzZWYtY2JiYy00N2IyLWFhOWMtYWVlOWNmZGFiNjViIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjYyNTk4MDYsImV4cCI6NDg4MjAxOTgwNn0.ZK78iZeuKpjMXjJp3QZSo6msf37m4hDZiEi10FjmgI4'
                        // }
                      }
                    ).then(async res => {
                      let data = await res.json()
                      if (data.items) {
                        let holderDatas = data.item
                        console.log('holderDatas', holderDatas)
                        if (holderDatas && holderDatas.length > 0) {
                          holderDatas.sort((a, b) => b.percentage_relative_to_total_supply - a.percentage_relative_to_total_supply)
                            .map((holder, index) => {
                              if (index < 15) {
                                top15HoldersPercent += holder.percentage_relative_to_total_supply
                              }
                              if (holder.owner_address.toString().toLowerCase() === devAddress.toString().toLowerCase()) {
                                devPercent = holder.percentage_relative_to_total_supply
                              }
                              holdersNumber++
                            })
                        }
                      }
                    })
                  }
                }
              } catch (e) {
                console.error(e)
              }
              const chadData = {
                chainId,
                startTime: startTime,
                progress: progress,
                Liquidity: liquidity,
                tokenName: name,
                tokenSymbol: symbol,
                tokenDescription: description,
                logoUrl: logoUrl,
                bannerUrl: bannerUrl,
                address: mainInfo[5][i],
                depositedAmount: Number(mainInfo[4][i]) * ethPrice / 10 ** 18,
                contractAddress: contractAddress,
                tokenAddress,
                devAddress: devAddress,
                dexName: dexName,
                marketCap: virtualLpAmounts / virtualLpTokenAmounts / 10 ** 9,
                website: website,
                twitter: twitter,
                telegram: telegram,
                blockchainLogoUrl: blockchainLogoUrl,
                raisingPercent: raisingPercent,
                isRising: isRising,
                featureTime,
                lpCreated,
                lastAction,
                lastActionNumber,
                top15HoldersPercent,
                devPercent,
                holdersNumber
              }
              setChadListData(prevState => [...prevState, chadData])
            }
          }
        }
        setAccumFee(totalAccumFee);

        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    FetchData()
  }, []);

  useEffect(() => {
    const storedWatchlist = JSON.parse(localStorage.getItem("watchlist")) || [];
    setWatchlist(storedWatchlist);
  }, []);

  useEffect(() => {
    localStorage.setItem("watchlist", JSON.stringify(watchlist));
  }, [watchlist]);

  const toggleWatchlist = (item) => {
    setWatchlist((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  const sortOptions = [
    { value: 'Market Cap', label: 'Market Cap' },
    { value: 'Time', label: 'Time' },
    { value: 'Volume', label: 'Volume' },
    { value: 'Last Reply', label: 'Last Reply' }
  ]

  const orderOptions = [
    { value: 'Descending', label: 'Descending' },
    { value: 'Ascending', label: 'Ascending' }
  ]

  const statusOptions = [
    { value: 'All', label: 'All' },
    { value: 'Listed', label: 'Listed' },
    { value: 'Live', label: 'Live' },
    { value: 'Favorite', label: 'Favorite' }
  ]

  const chainOptions = [
    { value: 'Chains', label: 'Chains' },
    { value: 'Ethereum', label: 'Ethereum' },
    { value: 'BSC', label: 'BSC' },
    { value: 'Base', label: 'Base' },
    { value: 'Arbitrum', label: 'Arbitrum' },
    { value: 'SubtensorEVM', label: 'SubtensorEVM' }
  ]

  function FilterSelect({ options, defaultValue, onChange }) {
    const handleChange = newValue => {
      onChange(newValue)
    }

    return (
      <Select
        defaultValue={defaultValue}
        isSearchable={false}
        options={options}
        value={options.find(option => option.value === defaultValue.value)}
        onChange={handleChange}
        styles={{
          control: styles => ({
            ...styles,
            color: 'white',
            padding: '4px 0px',
            border: 'none',
            backgroundColor: '#686c74',
            boxShadow: 'rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset',
            borderRadius: '10px',
            cursor: 'pointer',
            outline: 'none',
            '&:hover': {
              boxShadow: 'rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset',
            },
            '&:focus': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            }
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isFocused ? isSelected ? '#686c74' : '#686c74' : '#f3f3f3',
            color: 'black',
            cursor: 'pointer',
          }),
          singleValue: (styles, { isFocused }) => ({
            ...styles,
            color: 'white',
            outline: 'none',
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none'
          }),
          dropdownIndicator: styles => ({
            ...styles,
            color: 'white',
          }),
          menuList: styles => ({
            ...styles,
            background: '#f3f3f3',
            borderRadius: '5px'
          }),
          menu: styles => ({
            ...styles,
            zIndex: '49'
          })
        }}
      />
    )
  }

  const [sortedChadLists, setSortedChadLists] = useState([])
  const [filteredChadLists, setFilteredChadLists] = useState([])
  const [sortValue, setSortValue] = useState(sortOptions[0])
  const [orderValue, setOrderValue] = useState(orderOptions[0])
  const [statusValue, setStatusValue] = useState(statusOptions[0])
  const [chainValue, setChainValue] = useState(chainOptions[0])

  const filterChadLists = useCallback(() => {
    const searchFiltered = chadLists.filter((list) => {
      if (!search || search === '') {
        return true
      }

      const searchLower = search.toLowerCase()

      if (list.tokenName.toLowerCase().includes(searchLower)) {
        return true
      }
    })
    let chainFilteredList = []
    switch (chainValue.value) {
      case 'Chains':
        chainFilteredList = [...searchFiltered]
        break
      case 'SubtensorEVM':
        chainFilteredList = [...searchFiltered.filter(item => item.chainId === 945)]
        break
      case 'Base':
        chainFilteredList = [...searchFiltered.filter(item => item.chainId === 8453)]
        break
      case 'Ethereum':
        chainFilteredList = [...searchFiltered.filter(item => item.chainId === 1)]
        break
      case 'Arbitrum':
        chainFilteredList = [...searchFiltered.filter(item => item.chainId === 42161)]
        break
      case 'BSC':
        chainFilteredList = [...searchFiltered.filter(item => item.chainId === 56)]
        break
      default:
        break
    }
    let filteredList = []
    switch (statusValue.value) {
      case 'All':
        filteredList = [...chainFilteredList]
        break
      case 'Listed':
        filteredList = [...chainFilteredList.filter(item => item.progress >= 100)] // TODO: Add filtering logic for "Listed" condition
        break
      case 'Live':
        filteredList = [...chainFilteredList.filter(item => item.progress < 100)] // TODO: Add filtering logic for "Live" condition
        break
      case 'Favorite':
        filteredList = [...chainFilteredList.filter(item => watchlist.includes(item.contractAddress))] // TODO: Add filtering logic for "Live" condition
        break
      default:
        break
    }
    setFilteredChadLists(filteredList)
  }, [statusValue, chainValue, chadLists, search])

  const timeFilterChadLists = useMemo(() => {
    let timeFilteredList = []
    const now = Date.now() / 1000
    switch (selectedItem) {
      case '15m':
        timeFilteredList = [...chadLists.filter(item => Number(item.startTime) + 900 >= now)]
        break
      case '1H':
        timeFilteredList = [...chadLists.filter(item => Number(item.startTime) + 3600 >= now)]
        break
      case '6H':
        timeFilteredList = [...chadLists.filter(item => Number(item.startTime) + 21600 >= now)]
        break
      case '24H':
        timeFilteredList = [...chadLists.filter(item => Number(item.startTime) + 86400 >= now)]
        break
      case '1W':
        timeFilteredList = [...chadLists.filter(item => Number(item.startTime) + 604800 >= now)]
        break
      case '2W':
        timeFilteredList = [...chadLists.filter(item => Number(item.startTime) + 1209600 >= now)]
        break
      case '1M':
        timeFilteredList = [...chadLists.filter(item => Number(item.startTime) + 2592000 >= now)]
        break
      case 'All time':
        timeFilteredList = [...chadLists]
        break
      default:
        break
    }
    return timeFilteredList
  }, [selectedItem, chadLists])

  const onSearchChanged = (event) => {
    setSearch(event.target.value);
  };

  const sortChadLists = useCallback(async () => {
    const latestChatsRes = await fetch(apiUrl + `/api/getLatestChats`, { method: 'GET' })
    const latestChats = await latestChatsRes.json()
    let sortedList = []
    const timestampMap = new Map(
      latestChats.map(chat => [chat.ChadAddress, chat.timestamp])
    );

    // Retrieve watchlist from localStorage
    const watchlist = JSON.parse(localStorage.getItem("watchlist")) || [];

    switch (sortValue.value) {
      case 'Market Cap':
        sortedList = [...filteredChadLists].sort((a, b) => {
          if (orderValue.value === 'Ascending') {
            return a.marketCap - b.marketCap
          } else {
            return b.marketCap - a.marketCap
          }
        })
        break
      case 'Time':
        sortedList = [...filteredChadLists].sort((a, b) => {
          if (orderValue.value === 'Ascending') {
            return a.startTime - b.startTime
          } else {
            return b.startTime - a.startTime
          }
        })
        break
      case 'Volume':
        sortedList = [...filteredChadLists].sort((a, b) => {
          if (orderValue.value === 'Ascending') {
            return a.depositedAmount - b.depositedAmount
          } else {
            return b.depositedAmount - a.depositedAmount
          }
        })
        break
      case 'Last Reply':
        sortedList = [...filteredChadLists].sort((a, b) => {
          if (orderValue.value === 'Ascending') {
            const timestampA = timestampMap.get(a.contractAddress) || 0;
            const timestampB = timestampMap.get(b.contractAddress) || 0;
            console.log('asce', timestampA, timestampB)

            return timestampA - timestampB;
          } else {
            const timestampA = timestampMap.get(a.contractAddress) || 0;
            const timestampB = timestampMap.get(b.contractAddress) || 0;

            return timestampB - timestampA;
          }
        })
        break
      default:
        break
    }

    // Move watchlist items to the top
    sortedList.sort((a, b) => {
      const isAInWatchlist = watchlist.includes(a.contractAddress);
      const isBInWatchlist = watchlist.includes(b.contractAddress);
      return isBInWatchlist - isAInWatchlist; // Places watchlist items at the top
    });

    setSortedChadLists(sortedList)
  }, [orderValue, sortValue, filteredChadLists, watchlist])

  useEffect(() => {
    setFilteredChadLists([...chadLists])
  }, [chadLists, statusValue])

  useEffect(() => {
    filterChadLists()
  }, [statusValue, filterChadLists])

  useEffect(() => {
    sortChadLists()
  }, [orderValue, sortValue, filteredChadLists, sortChadLists])

  const onSortChange = newValue => {
    setSortValue(newValue)
    sortChadLists()
  }

  const onChainChange = val => {
    setChainValue(val)
    filterChadLists()
  }

  const onOrderChange = () => {
    if (orderValue.value === 'Descending')
      setOrderValue(orderOptions[1])
    else
      setOrderValue(orderOptions[0])
    sortChadLists()
  }

  const onStatusChange = newValue => {
    setStatusValue(newValue)
    filterChadLists()
  }
  // const [isHovering, setIsHovering] = useState(false);
  // const handleMouseEnter = () => {
  //   setIsHovering(true);
  //   setIsHovering(false);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovering(false);
  // };

  let refAddress
  if (cookies.get('ref')) {
    if (Web3.utils.isAddress(rot13(cookies.get('ref')))) {
      refAddress = rot13(cookies.get('ref'))
    }
  } else {
    refAddress = getDefaultAddress()
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onTokenSwap = async (chadAddress, tokenAddress, chainId, tokenSymbol) => {
    setPoolAddress(chadAddress)
    try {
      setCreating1(true)
      let swap
      let sendData;
      swap = await writeContract(wagmiAdapter.wagmiConfig, {
        address: chadAddress,
        abi: ChadAbi,
        functionName: 'buyToken',
        value: Math.floor(buyAmount * 10 ** 18 / etherPrice),
        args: [refAddress],
        account: address,
        gas: 7000000
      })
      await delay(8000); // 8-second delay
      sendData = {
        chainId,
        buyer: address,
        type: 'bought',
        name: tokenSymbol,
        amount: Math.floor(buyAmount / etherPrice),
        token: tokenAddress,
        contract: chadAddress,
        timestamp: (Date.now() / 1000).toFixed(0)
      }
      if (sendData) {
        const response = await fetch(apiUrl + '/api/addHistory', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'error',
          body: JSON.stringify(sendData)
        })
        if (response.status !== 200) {
          const { error } = await response.json()
          throw new Error(error)
        }
      }
      setTimeout(function () {
        setCreating1(false)
      }, 5000)
      toast.success(`Successfully ${Number(buyAmount / etherPrice).toLocaleString()} ${coinNames[chainId]} swapped`)
    } catch (err) {
      console.log('>>>>', err)
      if (address === undefined) {
        toast.error('Connect wallet')
      } else {
        toast.error('There is a problem with your Swap. Please try again later')
      }
      setCreating1(false)
    }
  }

  const LaunchpadCardGrid = ({ items, buyAmount }) => {

    return (
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {items.map(
          (
            {
              startTime,
              chainId,
              progress,
              Liquidity,
              tokenName,
              tokenSymbol,
              tokenDescription,
              logoUrl,
              bannerUrl,
              address,
              depositedAmount,
              contractAddress,
              tokenAddress,
              devAddress,
              dexName,
              marketCap,
              website,
              twitter,
              telegram,
              blockchainLogoUrl,
              lpCreated,
              lastAction,
              lastActionNumber,
              top15HoldersPercent,
              devPercent,
              holdersNumber
            },
            i
          ) => (
            <LaunchpadCard
              apiUrl={apiUrl}
              coinNames={coinNames}
              web3Clients={web3Clients}
              buyAmount={buyAmount}
              startTime={startTime}
              chainId={chainId}
              progress={progress}
              Liquidity={Liquidity}
              tokenName={tokenName}
              tokenSymbol={tokenSymbol}
              tokenDescription={tokenDescription}
              Logo={<img src={logoUrl} className="rounded-full sm:w-[54px] sm:h-[54px] w-[36px] h-[36px]" />}
              Banner={bannerUrl}
              chadAddress={address}
              depositedAmount={depositedAmount}
              contractAddress={contractAddress}
              tokenAddress={tokenAddress}
              devAddress={devAddress}
              dexName={dexName}
              marketCap={marketCap}
              website={website}
              twitter={twitter}
              telegram={telegram}
              BlockchainLogo={
                <img
                  src={blockchainLogoUrl}
                  className="launchpad-blockchain-logo"
                />
              }
              lpCreated={lpCreated}
              watchlist={watchlist}
              toggleWatchlist={toggleWatchlist}
              lastAction={lastAction}
              lastActionNumber={lastActionNumber}
              top15HoldersPercent={top15HoldersPercent}
              devPercent={devPercent}
              holdersNumber={holdersNumber}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            />
          )
        )}
      </div>
    )
  }

  // Modal Section
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
    cookies.set("show-how-it-works", "true")
  }

  const closeModal = e => {
    if (e.target.id === 'modal') {
      setModalIsOpen(false)
      cookies.set("show-how-it-works", "true")
    }
  }

  const modalContent = (
    <div
      id="modal"
      onClick={closeModal}
      className={`modal ${modalIsOpen ? 'show-modal' : ''}`}
    >
      <div className="bg-[#f3f3f3] rounded-[25px] max-w-[480px] p-[24px]">
        <h1 className='text-center text-xl pb-2 font-extrabold'>All created tokens in hotcurves.fun are safe!</h1>
        <p className='text-[#222]'>✅Each coin is a fair-launch with no presale and no team allocation.</p>
        <p className='text-[#222]'>✅Everyone has equal chance!</p>
        <h1 className='text-center text-xl pb-2 font-extrabold'>How it works</h1>
        <p className='text-[#222]'>1️⃣ pick a coin that you like</p>
        <p className='text-[#222]'>2️⃣ buy the coin on the bonding curve</p>
        <p className='text-[#222]'>3️⃣ sell at any time to lock in your profits or losses</p>
        <p className='text-[#222]'>4️⃣ when enough people buy on the bonding curve it reaches a market cap of $69k and the token will be listed with $12k of liquidity deposited in the preferred DEX.</p>
        <p className='text-[#222] mb-4'>5️⃣LP Tokens will be burned🔥</p>
        <p className='text-[#222] italic text-[14px]'>Disclaimer:</p>
        <p className='text-[#222] italic text-[14px]'>Trading tokens is highly risky / speculative. Do not invest more than you can afford to lose. Anyone can list a token, listing does not mean we endorse the token. Token prices can be extremely volatile. Be sure to follow any legal guidelines that your country specifies.</p>
        <div className='text-center mt-4'>
          <button onClick={toggleModal} className="rounded-full bg-[#2f6434] px-4 py-3 text-white">
            I'm ready to pump
          </button>
        </div>
      </div>
    </div>
  )

  const Dropdown = ({ selectedItem, setSelectedItem }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleSelect = (item) => {
      setSelectedItem(item);
      setIsOpen(false);
    };

    return (
      <div className="relative inline-block" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex gap-2 items-center py-1 px-3 bg-[#454547] text-white rounded hover:opacity-80"
        >
          <img src={ClockIcon} width={15} />
          {selectedItem}
        </button>

        {isOpen && (
          <div className="absolute left-0 mt-2 w-24 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
            <ul className="py-2">
              <li className="text-[15px] px-2 py-1 mb-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect('15m')}>15m</li>
              <li className="text-[15px] px-2 py-1 mb-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect('1H')}>1H</li>
              <li className="text-[15px] px-2 py-1 mb-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect('6H')}>6H</li>
              <li className="text-[15px] px-2 py-1 mb-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect('24H')}>24H</li>
              <li className="text-[15px] px-2 py-1 mb-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect('1W')}>1W</li>
              <li className="text-[15px] px-2 py-1 mb-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect('2W')}>2W</li>
              <li className="text-[15px] px-2 py-1 mb-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect('1M')}>1M</li>
              <li className="text-[15px] px-2 py-1 mb-1 hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect('All time')}>All time</li>
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <style jsx>{`
        .modal {
          opacity: 0;
          visibility: hidden;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          z-index: 10;
        }

        .show-modal {
          opacity: 1;
          visibility: visible;
        }
      `}</style>
      {modalContent}
      <div className="GlobalContainer launches-all-padding">
        <div>
          <TopBar />
          <div className="max-w-[90rem] m-auto pt-36 pb-24 px-4 sm:px-12 sm:py-10">
            <div className='my-12 sm:my-16 md:my-8 flex justify-end md:justify-between items-center relative px-36 lg:pl-52 pr-8'>
              <div className='md:flex flex-col gap-1 z-10 hidden'>
                <div className='text-[#f3f3f3] text-lg'>Token Creators Earn Lifetime Revenues!</div>
                <div className='text-[#b9b9b9] text-[14px]'>Every trade on your token’s V3 pool generates passive income while liquidity stays locked forever.</div>
              </div>
              <div className='flex flex-col gap-1 z-10 items-end'>
                <div className='text-[#f3f3f3] text-base'>Total Earned Fees</div>
                <div className='text-[#f3f3f3] text-lg'>${accumFee?.toFixed(2)}</div>
              </div>
              <img src={banner} className="w-full md:block hidden rounded-[15px] absolute left-0 z-0" />
              <img src={banner1} className="w-full block md:hidden rounded-[25px] absolute left-0 z-0" />
            </div>
            <div className='pb-4 sm:py-6 flex m-auto justify-center gap-4'>
              {/* 
              <a href="https://taobridge.hotkeyswap.com/" target="_blank" rel="noopener noreferrer" className="text-[#f3f3f3] transform transition-transform duration-200 hover:scale-110 rounded-full bg-[#686c74] px-6 py-4 text-base sm:text-xl font-bold sm:font-extrabold border border-[#5e5e5e33] h-[48px] sm:h-[60px] text-[22px] flex items-center" style={{ boxShadow: "#5e5e5e33 1px 3px 0px 0px" }}>
                Bridge
              </a>
              */}
              <Link to="/createAgent" className="text-[#f3f3f3] transform transition-transform duration-200 hover:opacity-80 rounded-xl bg-[#686c74] px-6 py-4 text-base sm:text-xl font-bold sm:font-extrabold border border-[#5e5e5e33] h-[48px] sm:h-[60px] text-[22px] flex items-center" style={{ boxShadow: "rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset" }}>
                Create Token
              </Link>
              {/* 
              <a href="https://taodex.hotkeyswap.com/" target="_blank" rel="noopener noreferrer" className="text-[#f3f3f3] transform transition-transform duration-200 hover:opacity-80 rounded-full bg-[#686c74] px-6 py-4 text-base sm:text-xl font-bold sm:font-extrabold border border-[#5e5e5e33] h-[48px] sm:h-[60px] text-[22px] flex items-center" style={{ boxShadow: "rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset" }}>
                DEX
              </a>
              */}
            </div>
            <div className='max-w-[720px] mx-auto bg-[#12141688] border border-[#929292] rounded lg:px-8 px-2.5 py-5' style={{ boxShadow: "#8c00f5 0px 5px 10px 0px" }}>
              <div className='flex justify-between items-center'>
                <div className='flex gap-2'>
                  <div className='bg-purple-500 rounded text-white py-1 px-3'>Top 10</div>
                  <Dropdown selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
                </div>
                <div className='flex items-center py-1 px-2 gap-2 bg-[#5e5e5e33] text-white rounded border border-[#7c3bb3]'>
                  <div className='flex items-center text-[#bcbcbc]'>
                    <img src={ThunderIcon} width={16} />
                    <span className=''>Buy : </span>
                  </div>
                  $<input className='outline-none w-6' type='number' value={buyAmount} onChange={(e) => setBuyAmount(e.target.value)} />
                </div>
              </div>
              <div className="mt-3 w-full text-xs sm:text-sm">
                <div className='gap-2 flex flex-col text-[#f8ffe8]'>
                  {loading && (
                    <div className='bg-[#5e5e5e33] rounded-[8px]'>
                      <div className="px-4 py-3 text-center">Loading...</div>
                    </div>
                  )}
                  {!loading && timeFilterChadLists?.length === 0 && (
                    <div className='bg-[#5e5e5e33] rounded-[8px]'>
                      <div className="px-4 py-2.5 text-center">No Data</div>
                    </div>
                  )}
                  {!loading && timeFilterChadLists && timeFilterChadLists.sort((a, b) => b.progress - a.progress).slice(0, 10).map((pool, index) => (
                    <>
                      <div className='flex items-center'>
                        <div key={index} className='grid grid-cols-12 items-center text-xs sm:text-sm grow'>
                          <div className="col-span-2 sm:col-span-1 rounded-l-[25px] text-center">
                            {index === 0 && '🥇'}
                            {index === 1 && '🥈'}
                            {index === 2 && '🥉'}
                            {index + 1}
                          </div>
                          <div className="font-semibold lg:font-normal col-span-6 sm:col-span-3 flex items-center justify-between pr-2">
                            <Link to={`/buy/?chain=${pool.chainId}&address=${pool.address}`}>
                              <div className='flex flex-row items-center text-[#c378fb] gap-1'>
                                <img src={pool.logoUrl} className='h-[30px] w-[30px] rounded-full' />
                                <div className='text-[10px] sm:text-[12px] inline-block whitespace-nowrap overflow-hidden overflow-hidden'>{pool.tokenSymbol}</div>
                              </div>
                            </Link>
                            <img src={pool.blockchainLogoUrl} className='h-[18px] w-[18px] rounded-full' />
                          </div>
                          <div className='flex gap-2 border-l border-[#222] justify-center col-span-4 sm:col-span-2'>
                            <img src={ChartIcon} width={18} />
                            <span className={`${pool.isRising ? 'text-green-500' : 'text-red-500'}`}>{Number(pool.progress).toLocaleString()}</span>
                          </div>
                          <div className='hidden sm:flex gap-2 border-l border-[#222] justify-center col-span-2'>
                            <img src={UsersIcon} width={18} />
                            <span className={`${pool.isRising ? 'text-green-500' : 'text-red-500'}`}>{formatNumber(pool.holdersNumber)}</span>
                          </div>
                          <div className='hidden sm:flex gap-2 border-l border-[#222] justify-center col-span-2'>
                            <span className='text-white'>MC</span>
                            <span className={`${pool.isRising ? 'text-green-500' : 'text-red-500'}`}>${formatNumber(Math.floor(pool.marketCap))}</span>
                          </div>
                          <div className='hidden sm:flex gap-2 border-l border-[#222] justify-center col-span-2'>
                            <span className='text-white'>V</span>
                            <span className={`${pool.isRising ? 'text-green-500' : 'text-red-500'}`}>${formatNumber(Math.floor(pool.depositedAmount))}</span>
                          </div>
                        </div>
                        <div className='flex items-center w-24'>
                          <div className='flex gap-2 border-x border-[#222] px-2'>
                            <button onClick={() => toggleWatchlist(pool.address)}>
                              <img src={watchlist.includes(pool.address) ? StarIcon : StarlineIcon} width={18} />
                            </button>
                          </div>
                          <div className='flex pl-2'>
                            <button onClick={() => onTokenSwap(pool.address, pool.tokenAddress, pool.chainId, pool.tokenSymbol)} className='flex items-center border border-[#a135bb] rounded-[8px] px-2 py-1 hover:scale-95 cursor-pointer disabled:opacity-30 disabled:cursor-not-allowed' disabled={buyAmount === '' || pool.lpCreated}>
                              {(creating1 && pool.address === poolAddress) ? <ClipLoader
                                color={'#a135bb'}
                                loading={creating1}
                                size={18}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              /> : <img src={ThunderIcon} width={18} />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            {chadLists.filter(item => item.progress >= 100).length > 0 && <div className='flex flex-col mt-[36px] xl:mt-[18px]'>
              <div className='text-[#f3f3f3] text-xl'>Listed Projects</div>
              <Slider items={chadLists.filter(item => item.progress >= 100)} isListed={true} />
            </div>}
            {chadLists.filter(item => item.featureTime > Date.now() / 1000).length > 0 && <div className='flex flex-col mt-[36px] xl:mt-[18px]'>
              <div className='text-[#f3f3f3] text-xl'>Featured Projects</div>
              <Slider items={chadLists.filter(item => item.featureTime > Date.now() / 1000)} isListed={false} />
            </div>}
            <div className="py-[32px] w-full h-auto">
              <div className="flex flex-col lg:flex-row gap-3 lg:gap-2.5 h-full lg:justify-between">
                <div
                  className="border border-[#f3f3f3] rounded-full relative w-full lg:w-[350px] h-10 lg:h-full">
                  <svg fill="#c5c5c0" viewBox="0 0 18 18" width="18" className="top-3 left-[14px] absolute">
                    <g>
                      <path
                        d="M7.75987 15.5197C3.48078 15.5197 0 12.039 0 7.75987C0 3.48078 3.48078 0 7.75987 0C12.039 0 15.5197 3.48078 15.5197 7.75987C15.5197 12.039 12.039 15.5197 7.75987 15.5197ZM7.75987 1.90911C4.53381 1.90911 1.90911 4.53381 1.90911 7.75855C1.90911 10.9833 4.53381 13.608 7.75987 13.608C10.9859 13.608 13.6106 10.9833 13.6106 7.75855C13.6106 4.53381 10.9859 1.91042 7.75987 1.91042V1.90911Z">
                      </path>
                      <path
                        d="M16.8235 17.9987C16.5228 17.9987 16.2221 17.8845 15.9924 17.6547L11.9378 13.6001C11.4783 13.1406 11.4783 12.3974 11.9378 11.9379C12.3974 11.4783 13.1418 11.4783 13.6001 11.9379L17.6546 15.9924C18.1142 16.452 18.1142 17.1951 17.6546 17.6547C17.4249 17.8845 17.1242 17.9987 16.8235 17.9987Z">
                      </path>
                    </g>
                  </svg>
                  <input
                    className="bg-transparent placeholder:text-[#c5c5c0] placeholder:text-sm focus:outline-none py-[12px] pr-8 pl-[44px] border rounded-2xl w-full h-full text-[#f8ffe8]"
                    placeholder="Search Token"
                    onChange={onSearchChanged} />
                </div>
                <div className='flex flex-col sm:flex-row gap-2 text-[14px]'>
                  <div className='flex gap-2'>
                    <FilterSelect
                      options={sortOptions}
                      defaultValue={sortValue}
                      onChange={onSortChange}
                    />
                    <FilterSelect
                      options={statusOptions}
                      defaultValue={statusValue}
                      onChange={onStatusChange}
                    />
                  </div>
                  <div className='flex gap-2'>
                    <FilterSelect
                      options={chainOptions}
                      defaultValue={chainValue}
                      onChange={onChainChange}
                    />
                    <button className='bg-[#686c74] rounded-full px-4 sm:p-2' onClick={onOrderChange} style={{
                      boxShadow: 'rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset',
                      borderRadius: '10px'
                    }}>
                      {orderValue.label === 'Ascending' ?
                        <svg fill="#f5f5f5" viewBox="0 0 21 17" width="21" className="fill-primary transition-transform "><g><path d="M17.2974 12.6684L19.0757 10.8901C19.5165 10.4493 20.2292 10.4493 20.67 10.8901C21.1109 11.331 21.1109 12.0437 20.67 12.4845L17.0607 16.0925C16.9025 16.2953 16.6807 16.4387 16.4306 16.4982C15.8274 16.651 15.2148 16.2872 15.062 15.6841C15.0228 15.5299 15.0174 15.3703 15.0444 15.2135V1.12783C15.0444 0.505763 15.5488 0 16.1723 0C16.7957 0 17.3001 0.504411 17.3001 1.12783V12.6684H17.2974ZM1.12783 1.45373H10.8942C11.5163 1.45373 12.022 1.95814 12.022 2.58156C12.022 3.20497 11.5176 3.70938 10.8942 3.70938H1.12783C0.504411 3.70803 0 3.20362 0 2.5802C0 1.95679 0.504411 1.45373 1.12783 1.45373ZM1.12783 7.46474H10.8942C11.5163 7.44446 12.0383 7.93264 12.0585 8.5547C12.0788 9.17677 11.5906 9.69876 10.9686 9.71904C10.9442 9.71904 10.9185 9.71904 10.8942 9.71904H1.12783C0.505763 9.71904 0 9.21463 0 8.59122C0 7.9678 0.504411 7.46339 1.12783 7.46339V7.46474ZM1.12783 13.4758H10.8942C11.5163 13.4758 12.022 13.9802 12.022 14.6036C12.022 15.227 11.5176 15.7314 10.8942 15.7314H1.12783C0.505763 15.7314 0 15.227 0 14.6036C0 13.9802 0.504411 13.4758 1.12783 13.4758Z"></path></g></svg>
                        :
                        <svg fill="#f5f5f5" viewBox="0 0 21 17" width="21" className="fill-primary transition-transform rotate-180"><g><path d="M17.2974 12.6684L19.0757 10.8901C19.5165 10.4493 20.2292 10.4493 20.67 10.8901C21.1109 11.331 21.1109 12.0437 20.67 12.4845L17.0607 16.0925C16.9025 16.2953 16.6807 16.4387 16.4306 16.4982C15.8274 16.651 15.2148 16.2872 15.062 15.6841C15.0228 15.5299 15.0174 15.3703 15.0444 15.2135V1.12783C15.0444 0.505763 15.5488 0 16.1723 0C16.7957 0 17.3001 0.504411 17.3001 1.12783V12.6684H17.2974ZM1.12783 1.45373H10.8942C11.5163 1.45373 12.022 1.95814 12.022 2.58156C12.022 3.20497 11.5176 3.70938 10.8942 3.70938H1.12783C0.504411 3.70803 0 3.20362 0 2.5802C0 1.95679 0.504411 1.45373 1.12783 1.45373ZM1.12783 7.46474H10.8942C11.5163 7.44446 12.0383 7.93264 12.0585 8.5547C12.0788 9.17677 11.5906 9.69876 10.9686 9.71904C10.9442 9.71904 10.9185 9.71904 10.8942 9.71904H1.12783C0.505763 9.71904 0 9.21463 0 8.59122C0 7.9678 0.504411 7.46339 1.12783 7.46339V7.46474ZM1.12783 13.4758H10.8942C11.5163 13.4758 12.022 13.9802 12.022 14.6036C12.022 15.227 11.5176 15.7314 10.8942 15.7314H1.12783C0.505763 15.7314 0 15.227 0 14.6036C0 13.9802 0.504411 13.4758 1.12783 13.4758Z"></path></g></svg>
                      }
                    </button>
                    <div className='flex px-2 gap-2 bg-[#686c74] rounded-[10px] text-white' style={{ boxShadow: 'rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset' }}>
                      <div className='flex items-center'>
                        <img src={ThunderIcon} width={16} />
                        <span className=''>Buy : $</span>
                      </div>
                      <input className='outline-none w-12' type='number' value={buyAmount} onChange={(e) => setBuyAmount(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {sortedChadLists.length > 0 ? (
              <>
                <LaunchpadCardGrid
                  items={sortedChadLists}
                  buyAmount={Number(buyAmount) / etherPrice}
                />

                {loading === true ? (
                  <div className="loadingBox">
                    <div className="EmptyLaunchpad">
                      <div className="loadingBox">
                        <p className="Text1" style={{ color: 'white' }}>
                          Loading...
                        </p>
                        <ClipLoader
                          color={'#afccc6'}
                          loading={true}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </div>
                  </div>
                ) : currentLength > 0 ? (
                  <p className="loadMoreText" onClick={loadMoreLists()}>
                    Load more ...
                  </p>
                ) : (
                  <></>
                )}
              </>
            ) :
              (
                <div className="loadingBox">
                  <p className="Text1" style={{ color: 'white' }}>
                    No data yet
                  </p>
                </div>
              )
            }
            {/* <Partner />
            <div
              className="grid xl:grid-cols-1 gap-4"
              style={{ marginTop: '20px' }}
            >
              <div className="flex flex-col gap-3">
                <div
                  className="flex flex-col-reverse lg:flex-row bg-[#FFC000] rounded-[40px] lg:px-8 px-2.5 py-5"
                  style={{
                    boxShadow: 'rgb(103, 103, 103) 0px 5px 10px 0px',
                    border: '2px solid white',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <div
                      className="text-xl mb-1.5 refer-heading"
                      style={{
                        width: '80%',
                        textAlign: 'center',
                        fontSize: '35px',
                        fontWeight: 'black',
                        textTransform: 'uppercase',
                        fontWeight: 'bold'
                      }}
                    >
                      Refer HOTKEY to your friends and start earning
                    </div>
                    <div
                      className="Text1"
                      style={{
                        width: '100%',
                        fontSize: '15px',
                        color: 'black'
                      }}
                    >
                      Introduce your friends to a better way to trade. Refer
                      them to Blackoump.fun, and you will get{' '}
                      <span className='fees-bold'>
                        50% of the fees
                      </span>
                    </div>
                  </div>
                  <div>
                    <img style={{ height: '180px' }} src={footericon} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
