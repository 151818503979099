import React, { useState, useEffect, useRef } from 'react'
import '../App.css'
import '../styles/MainContainer.css'
import './aboutus.css'
import 'react-datepicker/dist/react-datepicker.css'
import TopBar from '../components/TopBar.jsx'
import faq from '../icons/faq.webp'
import Footer from '../components/Footer.jsx'

function Faq() {
  const [visibleSection, setVisibleSection] = useState(null) // Manages which section is visible
  const [toggle, setToggle] = useState(true)

  const toggleVisibility = section => {
    if (visibleSection === section) {
      setVisibleSection(null) // Close the section if it's already open
    } else {
      setVisibleSection(section) // Open the clicked section and close others
    }
  }
  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className='p-6 mt-[147px] sm:mt-0 md:p-[80px] flex flex-col-reverse xl:flex-row gap-8 items-center xl:items-start xl:gap-[0px] text-white justify-center'>
            <div className='flex flex-col max-w-[800px] gap-6 md:gap-[40px]'>
              <div className="m-auto px-4 sm:px-12 sm:py-10">
                <h1 className="text-[48px] leading-[110%] tracking-[-2%] font-bold">How it works</h1>
                <section className="lg:mx-auto pt-4 lg:py-[30px] w-full lg:w-[741px] min-w-0">
                  <div
                    className="Text1 text-[20px] font-light"
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    hotcurves.fun ensures safety and prevents rugs by guaranteeing that all created tokens are secure. Every coin is a fair launch with no presale and no team allocation. 
                  </div>
                  <a href="https://docs.hotkeyswap.com" target="_blank" className="p-[4px]">
                    <div
                      className="Text1 text-[20px]"
                      style={{
                        textAlign: 'center',
                        color: '#f500ff',
                        textDecoration: 'underline'
                      }}
                    >
                      Explore our Docs
                    </div>
                  </a>
                </section>
              </div>
              {/*
              <div className="m-auto sm:px-12 w-full">
                <div className="flex justify-start gap-0 relative">
                  <div className="absolute h-[48px] bg-[#686c74] rounded-[8px] transition-all duration-300 ease-in-out" style={{ width: `${toggle ? '120px' : '140px'}`, left: `${toggle ? '0px' : '120px'}` }}></div>
                  <button
                    className={`rounded-[8px] cursor-pointer h-[48px] px-6 text-[16px] font-semibold relative z-10 transition-colors duration-300 ${toggle ? 'text-white' : 'text-gray-400'}`}
                    onClick={() => setToggle(true)}
                  >
                    Investors
                  </button>
                  <button
                    className={`rounded-[8px] cursor-pointer h-[48px] px-6 text-[16px] font-semibold relative z-10 transition-colors duration-300 ${!toggle ? 'text-white' : 'text-gray-400'}`}
                    onClick={() => setToggle(false)}
                  >
                    Developers
                  </button>
                </div>
                <div className="flex flex-col mt-6">
                  {!toggle && <div className="flex flex-col">
                    <div
                      className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                        , cursor: 'pointer'
                      }}
                      onClick={() => toggleVisibility(0)}
                    >
                      <div className="Text1 text-[11px] lg:text-[14px]" style={{ cursor: "pointer" }}>
                        How does hotcurves.fun benefit developers.
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                        className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 0 ? 'rotate-180' : ''}`}>
                        <path d="m6 9 6 6 6-6"></path>
                      </svg>
                    </div>
                    {visibleSection === 0 && (
                      <div
                        className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                        style={{
                          boxShadow: '#8c00f5 0px 5px 10px 0px',
                          border: '2px solid #8c00f5',
                          zIndex: '-1',
                          marginTop: '-27px',
                        }}
                      >
                        <div
                          className="Text1 text-[11px] lg:text-[14px]"
                          style={{
                            paddingTop: '16px'
                          }}
                        >
                          hotcurves.fun streamlines the token deployment process, making it fast, easy, and affordable. In just a minute, you can launch your token and instantly reach thousands of potential investors on the platform. Plus, with the FEATURED option, you gain premium exposure and marketing to maximize your token’s visibility. hotcurves.fun is the perfect platform for developers looking to bring their projects to life quickly and effectively.
                        </div>
                      </div>
                    )}
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(1)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            Which coding skills do i need to deploy a token?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 1 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 1 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px',
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              None! You don’t need any coding experience to deploy a token on hotcurves.fun. All you have to do is fill out a few details and pay the deployment transaction fee. It’s that simple—launch your token in no time without worrying about technical skills.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(2)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px] max-w-[90%]"
                            style={{ cursor: "pointer" }}
                          >
                            How much does it cost to launch a project with hotcurves.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 2 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 2 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px',
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              It’s super affordable! The BASIC launch costs only around $2 to $3 plus gas fees. If you want the FEATURED OPTION to ensure top visibility and marketing for your project, it’s about $250. You can choose what works best for your project, whether it’s a budget-friendly option or full-blown exposure!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(3)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{
                            }}
                          >
                            How can I start investing in hotcurves.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 3 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 3 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px',
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              It’s super simple! Just connect your wallet, and you’re good to go. Explore various projects on our homepage, where you can easily filter options to find the perfect investment for you, whether it’s based on the project type or your preferred blockchain. With just a few clicks, you’re on your way to discovering exciting new tokens!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(4)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            Are there trading fees on hotcurves.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 4 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 4 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px',
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              YES! There’s a small 1% fee on every trade. But here’s the fun part: you can earn 50% of those fees just by sharing your referral link! Each token page has its own referral link ready for you to grab, so refer your friends and start earning today. It’s that easy!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(5)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px] max-w-[90%]"
                            style={{ cursor: "pointer" }}
                          >
                            Are there other ways to earn money through
                            hotcurves.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 5 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 5 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              Absolutely! You don’t need to invest to make money. Refer your friends and earn{' '}
                              <span style={{ fontWeight: 'bold' }}>
                                {' '}
                                50% of the fees{' '}
                              </span>
                              on every trade they make. It’s easy—just grab your referral link from any token page and share it to start earning. hotcurves.fun makes earning as simple as sharing!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>}

                  {toggle && <div className="flex flex-col">
                    <div
                      className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                        , cursor: 'pointer'
                      }}
                      onClick={() => toggleVisibility(6)}
                    >
                      <div
                        className="Text1 text-[11px] lg:text-[14px]"
                        style={{ cursor: "pointer" }}
                      >
                        What is hotcurves.fun?
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                        className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 6 ? 'rotate-180' : ''}`}>
                        <path d="m6 9 6 6 6-6"></path>
                      </svg>
                    </div>
                    {visibleSection === 6 && (
                      <div
                        className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                        style={{
                          boxShadow: '#8c00f5 0px 5px 10px 0px',
                          border: '2px solid #8c00f5',
                          zIndex: '-1',
                          marginTop: '-27px'
                        }}
                      >
                        <div
                          className="Text1 text-[11px] lg:text-[14px]"
                          style={{
                            paddingTop: '16px'
                          }}
                        >
                         hotcurves.fun is your go-to platform for launching tokens without all the hassle! It’s built to make token deployment easy and fast, while giving investors a sneak peek into the hottest, most secure crypto projects before they take off. If you’re all about finding the next big thing in crypto—safely—then you’re in the right place!
                        </div>
                      </div>
                    )}
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(7)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            How does hotcurves.fun make life sweeter for investors? 
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 7 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 7 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              It gives you an insider pass to brand-new tokens before they hit the big DEX stages! This means you get a chance to scoop up tokens early, boosting your potential for major returns while keeping things secure. Early bird gets the best crypto worms! 
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(8)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            Is investing in hotcurves.fun safe?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 8 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 8 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              Absolutely! hotcurves.fun takes the guesswork out of safety by managing both the liquidity pool and token contract. This creates a far more secure environment than the wild, scam-filled markets you’re used to. Forget worrying about bad actors—hotcurves.fun keeps your investments protected while you focus on those crypto gains!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(9)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px]"
                            style={{ cursor: "pointer" }}
                          >
                            How can I start investing on hotcurves.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 9 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 9 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              Getting started is super simple! Just connect your wallet, and you’re all set. Browse through a variety of exciting projects right on our homepage. You can easily filter through them to find the perfect one for you, based on your favorite chain to trade on. It’s quick, easy, and puts you right at the heart of promising crypto opportunities!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(11)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px] max-w-[90%]"
                            style={{ cursor: "pointer" }}
                          >
                            What happens when a token reaches a $69,000 market cap?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 11 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 11 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              When a token hits the $69,000 market cap milestone, it’s automatically listed on a DEX with $12,000 in liquidity. Plus, to boost safety, the LP tokens are burned, and the contract is renounced—giving investors even more peace of mind while trading. It’s all about making the process more secure and seamless for you!
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="grid xl:grid-cols-1 gap-12"
                      style={{ marginTop: '20px' }}
                    >
                      <div className="flex flex-col">
                        <div
                          className="bg-[#12141688] border border-[#8c00f5] rounded-[10px] lg:px-8 px-2.5 py-5"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            , cursor: 'pointer'
                          }}
                          onClick={() => toggleVisibility(12)}
                        >
                          <div
                            className="Text1 text-[11px] lg:text-[14px] max-w-[90%]"
                            style={{ cursor: "pointer" }}
                          >
                            Are there other ways to earn money through hotcurves.fun?
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className={`h-6 stroke-[#FECA18] w-6 shrink-0 transition-transform duration-200 ${visibleSection === 12 ? 'rotate-180' : ''}`}>
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </div>
                        {visibleSection === 12 && (
                          <div
                            className="bg-[#5e5e5e33] rounded-[20px] lg:px-8 px-2.5 py-5"
                            style={{
                              boxShadow: '#8c00f5 0px 5px 10px 0px',
                              border: '2px solid #8c00f5',
                              zIndex: '-1',
                              marginTop: '-27px'
                            }}
                          >
                            <div
                              className="Text1 text-[11px] lg:text-[14px]"
                              style={{
                                paddingTop: '16px'
                              }}
                            >
                              Yes, absolutely! You don’t need to invest money to start earning. Launch your project or refer friends to earn{' '}
                              <span style={{ fontWeight: 'bold' }}>
                                {' '}
                                50% of the fees.{' '}
                              </span>
                              Simply grab your referral link from any token page, and you’re ready to go! Whether you’re investing or spreading the word, you can make your mark on hotcurves.fun.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
              */}
              <div className="m-auto px-4 sm:px-12 sm:py-10">
                <section className="lg:mx-auto pt-4 lg:py-[30px] w-full lg:w-[741px] min-w-0">
                  <div
                    className="Text1 text-[20px] font-light"
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    Digital assets are highly speculative and involve significant risk of loss. The value of meme coins is extremely volatile. hotcurves.fun makes no representations regarding the success or profitability of any meme coin developed on the platform. Users should assess their financial situation, risk tolerance, and do their own research before trading. hotcurves.fun will not be held liable for any losses or issues that may arise.
                  </div>
                </section>
              </div>
            </div>
            <div className='w-[454.78px] h-[420px] hidden md:flex'>
              <img src={faq} width={454} height={420} className='' />
            </div>
            <div className='w-[214px] h-[199px] flex md:hidden'>
              <img src={faq} width={214} height={200} className='' />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Faq
